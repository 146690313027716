import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import React, {useEffect, useState} from 'react';
import {isMobile} from "../../services/helper";

const SponsorsSlider = () => {
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        axios.get(`${process.env.API_URL}/sponsors`).then((res) => {
            setSponsors(res.data)
        })
    };
    const [sponsors, setSponsors] = useState(false);
    const onChange = (index, item) => {
        setIntervalz(item.props["data-interval"]);
    };
    const [intervalz, setIntervalz] = useState(4000); //initial state here represents the interval for first image.

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 7,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 5,
            slidesToSlide: 5 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    if (sponsors === false) {
        return (<>Loading...</>);
    }
    const renderSponsor = () => {
        if (sponsors === false) {
            return 'loading..'
        }
        return sponsors.map((sponsor) => {
            return (<img key={sponsor.id} src={`${process.env.API_URL + sponsor.image.url}`} alt={`${sponsor.name}`}/>);
        })
    }
    const deviceClass = () => {
        return isMobile() ? 'mobileLogoSlider' : 'desktopLogoSlider';
    }
    return (
        <div className="Logoslider">
            <Carousel onChange={onChange} autoPlay className={'sponsorsSlider ' + deviceClass()} responsive={responsive} interval={intervalz}
                      infinite={isMobile()}>
                {renderSponsor()}
            </Carousel>
        </div>
    );
}
export default SponsorsSlider;