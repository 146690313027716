import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";

export default () => {
    return (
        <div className="container  mt-5">
            <div className="offset-1 col-md-10 margin-left-0">
                <div className="row">
                    <div className='col-md-5 col-lg-5 manPhoto'>
                        <StaticImage className="mobilenone" src='../../images/newimage/mobileman.png' alt={'Aruzy Mobile'}/>
                    </div>
                    <div className='col-md-7 col-lg-7'>
                        <div className="logopromotext">
                            <StaticImage src='../../images/newimage/Group59.svg' alt={'Aruzy Mobile'}/>
                            <p>Come back for more. We offer the chance to repeat purchases and inspiration to plan full days out.</p>
                        </div>
                        <div className="logopromotext">
                            <StaticImage src='../../images/newimage/Group60.svg' alt={'Aruzy Mobile'}/>
                            <p>Use ARUZY’s discount codes in-person or at the checkout online to enjoy your experience for less.</p>
                        </div>
                        <div className="logopromotext">
                            <StaticImage src='../../images/newimage/Group61.svg' alt={'Aruzy Mobile'}/>
                            <p>Subscribe for fun days out, dining offers and full-day packages to pep up your social calendar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};