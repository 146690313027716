import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import axios from 'axios/index'
import { Link } from 'gatsby'
import { isMobile } from '../../services/helper'

export default () => {
  const onChange = (index, item) => {
    setIntervalz(item.props['data-interval'])
  }
  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    let deviceType = isMobile() ? 'Mobile' : 'Desktop'
    axios
      .get(`${process.env.API_URL}/banners`)
      .then((res) => {
        let bannersList = []
        res.data.forEach((index) => {
          if (index.device === deviceType) {
            bannersList.push(index)
          }
        })
        setBanner(bannersList)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function NewlineText(text) {
    return text.split('\n').map((str, i) => <p key={i}>{str}</p>)
  }

  const [intervalz, setIntervalz] = useState(4000) //initial state here represents the interval for first image.
  const [banner, setBanner] = useState([]) //initial state here represents the interval for first image.
  if (banner === false) {
    return <>Loading...</>
  }
  return (
    <>
      <Carousel
        className={'sliderHome'}
        autoPlay
        selectedItem={1}
        infiniteLoop
        interval={intervalz}
        showDots={true}
      >
        {banner
          .sort((a, b) => (a.id < b.id ? 1 : -1))
          .map((banner1, index) => (
            <div
              className={
                'container-fluid bannersection' +
                banner1.id +
                ' deviceType' +
                banner1.device
              }
              key={index}
              data-interval={intervalz}
              style={{
                backgroundImage:
                  'url(' + process.env.API_URL + banner1.image.url + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className={'container center-banner carouselImg' + index}>
                <div className='banner-text-wrapper sec-banner'>
                  <div className='banner-text text-center'>
                    {banner1.heading !== '' && (
                      <h1 className='banner-heading'>
                        <span>{banner1.heading}</span>
                      </h1>
                    )}
                    {banner1.description !== '' && (
                      <span
                        className='banner-des'
                        style={{ fontFamily: 'Livvic' }}
                      >
                        {NewlineText(banner1.description)}
                      </span>
                    )}
                    <div className={'col-md-12  text-center joinBtn'}>
                      <Link to='/packages' className='btn btn-primary'>
                        <span style={{ color: '#fff' }}>Join Now</span>
                      </Link>
                      {/* <a href={banner1.url} className='btn btn-primary'>
                        <span style={{ color: '#fff' }}>Join Now</span>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </>
  )
}
