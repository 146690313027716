import { Link } from 'gatsby'
import * as React from 'react'

const ImageCta = (props) => {
  return (
    <div className='imgcta '>
      <Link to={props.link} aria-label={props.heading}>
        <img src={props.image} alt={props.heading} />
        <div className='content'>
          <h3>{props.heading}</h3>
          <div className='slide_from_left'>
            <span className='btnicon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15.209'
                height='15.211'
                viewBox='0 0 19.209 19.211'
              >
                <path
                  d='M252.187,91.395l-9.6-9.605a.77.77,0,0,0-.521-.225l-5.306-.15a.78.78,0,0,0-.563.224L233.427,84.4a.765.765,0,0,0-.224.563l.149,5.307a.764.764,0,0,0,.224.521l9.606,9.606a.765.765,0,0,0,1.083,0l7.922-7.924a.765.765,0,0,0,0-1.083m-13.374-2.786a1.582,1.582,0,1,1,1.582-1.582,1.584,1.584,0,0,1-1.582,1.582'
                  transform='translate(-233.202 -81.415)'
                  fill='#fff'
                />
              </svg>
            </span>
            <span className='imagectabtnlable'>{props.btntext}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ImageCta
