import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import ImageCta from "../ImageCta";
import restaurantcta from "../../images/restaurant.png";
import car from "../../images/car.png";
import servicescta from "../../images/service.png";
import retailcta from "../../images/retail.png";

export default () => {
    return (
        <div className="container pt-60">
            <div className="offset-1 margin-left-0 col-md-10">
                <div className="row">
                    <div className="col-md-6">
                        <ImageCta image={restaurantcta} link="/category/restaurants" btntext="SEE MORE" heading="Restaurants"/>
                    </div>
                    <div className="col-md-6">
                        <ImageCta image={car} link="/category/experiences" btntext="SEE MORE" heading="Experiences"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ImageCta image={servicescta} link="/category/services" btntext="SEE MORE" heading="Services"/>
                    </div>
                    <div className="col-md-6">
                        <ImageCta image={retailcta} link="/category/retail" btntext="SEE MORE" heading="Retail"/>
                    </div>
                </div>
            </div>
        </div>
    );
};