import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import AruzySeo from "../components/aruzySeo.js"
import SliderHome from "../components/Home/SliderHome";
import LogoSlider from "../components/Home/LogoSlider";
import FirstSection from "../components/Home/FirstSection";
import Explore from "../components/Home/Explore";
import Bloglist from "../components/bloglist";
import HeaderTitle from "../components/HeaderTitle";

const HomePage = () => {
    return (
        <main>
            <AruzySeo title="Hundreds of discounts across hundreds of brands!"/>
            <Header/>
            <SliderHome/>
            <LogoSlider/>
            <div className="mb-4 logobgsection">
                <FirstSection/>
                <Explore/>
                <div className="container blogsrction">
                    <div className="col-md-12 text-center">
                        <HeaderTitle title={'Latest News'}/>
                    </div>
                    <Bloglist/>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default HomePage
